<ng-container *ngIf="shouldLabelFloat">{{ symbol }}</ng-container>
<input
  type="text"
  #input
  [placeholder]="_placeholder"
  (focusin)="onFocusIn($event)"
  (focusout)="onFocusOut($event)"
  (keyup)="handleKeyup($event)"
  (blur)="handleBlur($event)"
  [disabled]="disabled"
/>
