import { BooleanInput, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
  NgForm,
  UntypedFormControl,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

interface ICurrencyConfig {
  symbol: string;
  scale: number;
}

const CURRENCY_CONFIG_MAP: { [key: string]: ICurrencyConfig } = {
  ZAR: { symbol: 'R', scale: 2 },
  GBP: { symbol: '£', scale: 2 },
  EUR: { symbol: '€', scale: 2 },
  USD: { symbol: '$', scale: 2 },
};

class UnsupportedCurrencyException extends Error {
  constructor(currency: string) {
    super(`Currency '${currency}' is not configured for the CurrencyInputControlComponent`);
  }
}

@Component({
  selector: 'x-currency-input-control',
  templateUrl: './currency-input-control.component.html',
  styleUrls: ['./currency-input-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatFormFieldControl, useExisting: CurrencyInputControlComponent }],
})
export class CurrencyInputControlComponent
  implements ControlValueAccessor, MatFormFieldControl<any>, DoCheck, OnDestroy
{
  @Input()
  symbol?: string;

  @Input()
  scale: number = 2;

  @Input()
  min: number | null = null;

  @Input()
  max: number | null = null;

  @Input()
  get currency(): string {
    return this._currency;
  }
  set currency(c: string) {
    const currencyConfig = CURRENCY_CONFIG_MAP[c];
    if (!currencyConfig) {
      throw new UnsupportedCurrencyException(c);
    }

    this.symbol = currencyConfig.symbol;
    this.scale = currencyConfig.scale;
    this._currency = c;
  }
  private _currency: string;

  controlType = 'duration-input';

  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  onChange: any = () => {};
  onTouch: any = () => {};
  errorState = false;

  static nextId = 0;

  @HostBinding()
  id = `${this.controlType}-${CurrencyInputControlComponent.nextId++}`;

  @ViewChild('input', { static: true })
  inputRef: ElementRef<HTMLInputElement>;

  get empty() {
    return this.value === null || typeof this.value !== 'number';
  }

  @Input('aria-describedby')
  userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  _placeholder: string = '';

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): number | null {
    return this._value;
  }
  set value(value: number | null) {
    this.writeValue(value);
  }
  _value: number | null;

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    if (!this._parentFormField) {
      return true;
    } else {
      return this.focused || !this.empty;
    }
  }

  constructor(
    private _elementRef: ElementRef,
    private _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private _parentForm: NgForm,
    @Optional() private _parentFormGroup: FormGroupDirective,
    @Optional() private _parentFormField: MatFormField,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngDoCheck(): void {
    if (this.ngControl) this.updateErrorState();
  }

  handleKeyup(event: KeyboardEvent) {
    this.updateView();
    this.updateModel();
  }

  handleBlur(event: Event) {
    this.updateView(true);
    this.updateModel();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  writeValue(value: number | null): void {
    this._value = value;
    if (!this.empty && this._value !== null) {
      const scaledValue = this._value / Math.pow(10, this.scale);
      this.inputRef.nativeElement.value = String(scaledValue);
    } else {
      this.inputRef.nativeElement.value = '';
    }
    this.updateView(true);
    this.stateChanges.next();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('input')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this._elementRef.nativeElement.querySelector('input').focus();
    }
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.inputRef.nativeElement.select();
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouch();
      this.stateChanges.next();
    }
  }

  private updateErrorState() {
    const oldState = this.errorState;
    const parent = this._parentFormGroup || this._parentForm;
    const matcher = /*this.errorStateMatcher || */ this._defaultErrorStateMatcher;
    const control = this.ngControl ? (this.ngControl.control as UntypedFormControl) : null;
    const newState = matcher.isErrorState(control, parent);

    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  private updateModel() {
    const viewValue = this.inputRef.nativeElement.value;
    if (typeof viewValue === 'string' && viewValue.length > 0) {
      const replacementValue = viewValue.replace(/,/g, '');

      let modelValue = coerceNumberProperty(replacementValue);
      if (modelValue !== null) {
        modelValue = Math.round(modelValue * Math.pow(10, this.scale));
        /* 
          If you're wondering why we use Math.round here, it's because javascript doesn't maths. 
          
          Exibit A:
          if we used Math.floor, this would result in an incorrect value: (9.95 * 100 = 994.9999999999999)
          if we used Math.ceil, this would result in an incorrect value: (9.05 * 100 = 905.0000000000001)
        */

        // Check and enforce min/max constraints
        let valueChanged = false;

        // Apply min constraints if set
        if (this.min !== null && modelValue < this.min) {
          modelValue = this.min;
          valueChanged = true;
        }

        // Apply max constraints if set
        if (this.max !== null && modelValue > this.max) {
          modelValue = this.max;
          valueChanged = true;
        }

        // Update the input value if it was constrained
        if (valueChanged) {
          const scaledValue = modelValue / Math.pow(10, this.scale);
          this.inputRef.nativeElement.value = String(scaledValue);
          this.updateView(true);
        }
      }
      this._value = modelValue;
    } else {
      this._value = null;
    }
    this.stateChanges.next();
    this.onChange(this._value);
  }

  private updateView(blur = false) {
    let input_val = this.inputRef.nativeElement.value;

    if (input_val === '') {
      return null;
    }

    let original_len = input_val.length;
    let caret_pos = this.inputRef.nativeElement.selectionStart;

    if (input_val.indexOf('.') >= 0) {
      let decimal_pos = input_val.indexOf('.');

      let left_side = input_val.substring(0, decimal_pos);
      let right_side = input_val.substring(decimal_pos);

      left_side = this.formatNumber(left_side);
      if (left_side.length == 0) {
        left_side = '0';
      }
      left_side = left_side.replace(/^([0,]+)(?!$)/, '').replace(/^,*/, '');

      right_side = this.formatNumber(right_side, false);

      if (blur) {
        right_side += '0'.repeat(this.scale);
      }

      right_side = right_side.substring(0, this.scale);

      input_val = left_side + '.' + right_side;
    } else {
      input_val = this.formatNumber(input_val);

      if (blur && this.scale > 0) {
        input_val += '.' + '0'.repeat(this.scale);
      }
    }

    this.inputRef.nativeElement.value = input_val;

    var updated_len = input_val.length;
    caret_pos = updated_len - original_len + (caret_pos ?? 0);
    this.inputRef.nativeElement.setSelectionRange(caret_pos, caret_pos);

    return input_val;
  }

  private formatNumber(n: string, addCommas = true) {
    n = n.replace(/\D/g, '');
    if (addCommas) {
      n = n.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return n;
  }
}
